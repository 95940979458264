<template>
  <img
    v-bind-observe
    :style="{
      aspectRatio
    }"
    class="ua-image"
    :src="innerSrc"
    :data-src="src"
    alt="图片"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { formatWithOss } from './utils';
import defaultImg from '@/assets/image/default-img.png';

const format = (src, w) =>
  formatWithOss(src, [`image/resize,m_lfit,w_${w},limit_0`, `image/format,webp`]);
export default {
  name: 'UAImage',
  directives: {
    bindObserve: {
      inserted(el, binding, vnode) {
        const vm = vnode.context;
        if (vm.lazy) {
          const lazyLoadObserver = new IntersectionObserver(
            entries => {
              entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                  // entry.target.setAttribute(
                  //   'src',
                  //   formatWithOss(vm.src, [
                  //     `image/resize,m_mfit,w_${entry.target.offsetWidth},limit_0`,
                  //     `image/format,webp`
                  //   ])
                  // );
                  vm.width = entry.target.offsetWidth;
                  vm.exposed = true;
                  entry.target.setAttribute('data-exposed', '1');
                  lazyLoadObserver?.disconnect?.(entry.target);
                }
              });
            },
            {
              rootMargin: '1200px',
              threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
            }
          );
          el.setAttribute('data-exposed', '0');
          lazyLoadObserver.observe(el);
        } else {
          setTimeout(() => {
            vm.width = el.offsetWidth;
          });
          // setTimeout(() => {
          //   el.setAttribute(
          //     'src',
          //     formatWithOss(vm.src, [
          //       `image/resize,m_mfit,w_${el.offsetWidth},limit_0`,
          //       `image/format,webp`
          //     ])
          //   );
          // });
        }
      }
    }
  },
  props: {
    src: { type: String, default: undefined },
    lazy: { type: Boolean, default: true },
    scale: { type: Number, default: 2 },
    aspectRatio: {
      type: String,
      default: undefined
    }
    // tag: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      exposed: false,
      width: 0
    };
  },
  computed: {
    innerSrc() {
      // const scale = this.$store.getters.isMobile ? 2 : this.scale;
      if (this.lazy) {
        return this.src && this.exposed
          ? format(this.src, Math.floor(this.width * this.scale))
          : defaultImg;
      }
      return this.src && this.width > 0
        ? format(this.src, Math.floor(this.width * this.scale))
        : defaultImg;
    }
  }
  // methods: {
  //   setSrc(el) {
  //     const width = el.offsetWidth;
  //     console.log('lazyLoadObserver setSrc', this.src, el);
  //     const src = formatWithOss(this.src, [
  //       `image/resize,m_mfit,w_${width},limit_0`,
  //       `image/format,webp`
  //     ]);
  //     this.innerSrc = src;
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.ua-image {
  display: block;
  object-fit: contain;
}
</style>
