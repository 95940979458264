// import defaultImg from '@/assets/image/default-img.png';
import { isImageType } from '@/utils/tools';

export const formatWithOss = (src, processArr) => {
  try {
    if (!isImageType(src)) {
      // throw new Error(`formatWithOss error: invalid src ${src}`);
      console.log(`formatWithOss error: invalid src ${src}`);
      return src;
    }
    const url = new URL(
      src.replace('http://', 'https://').replace(/^\/\//, `${location.protocol}//`)
    );
    const key = 'x-oss-process';
    const ossProcessRes = processArr.join('|');
    url.searchParams.set(key, ossProcessRes);
    return decodeURIComponent(url.toString());
  } catch (error) {
    console.warn(new Error(`formatWithOss error: ${src}`, { cause: error }));
    return src;
  }
};

// export const SrcLoad = {
//   bind(el: HTMLElement, binding, vnode) {
//     // const vm = vnode.context;
//     const { immediate = false } = binding.value || {};
//     const src = el.getAttribute('src');
//     el.setAttribute('data-src', src);
//     el.setAttribute('src', defaultImg);

//     // console.log('lazyLoadObserver', vnode);
//     if (immediate) {
//       const lazyLoadObserver = new IntersectionObserver(
//         entries => {
//           entries.forEach(entry => {
//             if (entry.intersectionRatio > 0) {
//               entry.target.setAttribute(
//                 'src',
//                 formatWithOss(src, [
//                   `image/resize,m_mfit,w_${el.offsetWidth},limit_0`,
//                   `image/format,webp`
//                 ])
//               );
//               entry.target.setAttribute('data-exposed', '1');
//               lazyLoadObserver.disconnect();
//             }
//           });
//         },
//         {
//           rootMargin: '1200px',
//           threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
//         }
//       );
//       el.setAttribute('data-exposed', '0');
//       lazyLoadObserver.observe(el);
//     }
//   },
//   inserted(el: HTMLElement, binding) {
//     const { immediate = false } = binding.value || {};
//     const src = el.getAttribute('data-src');
//     if (!immediate) {
//       el.setAttribute(
//         'src',
//         formatWithOss(src, [`image/resize,m_mfit,w_${el.offsetWidth},limit_0`, `image/format,webp`])
//       );
//     }
//   }
//   // update(el) {
//   //   const src = el.getAttribute('data-src');
//   //   if ()
//   // }
// };

// const ImageComponent = {
//   name: 'UAImg',
//   props: {
//     src: { type: String, default: undefined },
//     alt: { type: String, default: 'UA Image' },
//     lazy: { type: Boolean, default: true }
//     // aspectRatio: {
//     //   type: String,
//     //   default: undefined
//     // },
//     // tag: {
//     //   type: Boolean,
//     //   default: false
//     // }
//   },
//   render(h, context) {
//     // console.log('GtImage functional', context);
//     const { props, listeners } = context;
//     const { src, ...other } = props;
//     return h('img', {
//       directives: ['src-format'],
//       src: '@/assets/image/default-img.png',
//       'data-src': src,
//       ...other,
//       on: listeners
//     });
//   }
// };

// export const SrcFormatPlugin = {
//   install(vm) {
//     vm.directive('src-load', SrcLoad);
//     // vm.component('UAImg', ImageComponent);
//   }
// };
